import { useLayoutEffect, useState } from "react";
import Vehicle from "./train.png";
import styles from "./Train.module.css";

const offset = 300;
const topSpeed = 4;
const minSpeed = 0;

const Train = ({ enabled, setEnabled }) => {
  const [speed, setSpeed] = useState(topSpeed);
  const [position, setPosition] = useState(-offset);

  useLayoutEffect(() => {
    const speedTimer = setTimeout(() => {
      if (enabled) {
        if (speed < topSpeed) {
          setSpeed(speed + 1);
        }
      } else {
        if (speed !== minSpeed) {
          setSpeed(speed - 1);
        }
      }
    }, 400);
    return () => clearTimeout(speedTimer);
  }, [enabled, speed]);

  useLayoutEffect(() => {
    const timer = setTimeout(() => {
      setPosition(position + speed);
      if (position > window.innerWidth + offset) {
        setPosition(-offset + 1);
      }
    }, 10);

    return () => clearTimeout(timer);
  }, [position, enabled, speed]);

  return (
    <div className={styles.container}>
      <div className={styles.train} style={{ left: position }}>
        <img
          draggable={false}
          alt="choo choo"
          src={Vehicle}
          onClick={() => setSpeed(speed + 10)}
        />
      </div>

      <div className={styles.line}></div>

      <div
        className={styles.green}
        style={{
          backgroundColor: enabled ? "#4dff4d" : "rgb(55 169 55)",
        }}
        onClick={() => setEnabled(!enabled)}
      ></div>

      <div
        className={styles.red}
        style={{
          backgroundColor: enabled ? "#bd0707" : "red",
        }}
        onClick={() => setEnabled(!enabled)}
      ></div>
    </div>
  );
};

export default Train;
