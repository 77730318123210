import styles from "./App.module.css";
import Linkedin from "./linkedin.svg";
import Github from "./github.svg";
import Saltire from "./Saltire.svg";
import Train from "./Train";
import { useState } from "react";

const Block = ({ url, name, description, emoji }) => {
  return (
    <div className={styles.block}>
      <a href={url}>{name}</a>
      {emoji}
      <p>{description}</p>
    </div>
  );
};

const App = () => {
  const [chooChoo, setChooChoo] = useState(false);

  return (
    <>
      <div className={styles.main}>
        <div className={styles.content}>
          <h1>Finlay Smith</h1>

          <div className={styles.blocks}>
            <Block
              url="https://visualedinburgh.co.uk"
              name="Edinburgh Transport Visualiser"
              emoji={"🚌"}
              description="Displaying Transport for Edinburgh's data on a map, with the ability to filter by service or fleet number."
            />
          </div>

          <div className={styles.socials}>
            <a href="https://www.linkedin.com/in/finsmith/">
              <img alt="LinkedIn" width={50} src={Linkedin} />
            </a>
            <a href="https://github.com/findoo">
              <img alt="GitHub" width={50} src={Github} />
            </a>
            <a href="#" onClick={() => setChooChoo(!chooChoo)}>
              <img alt="<3 Scotland" src={Saltire} width={50} />
            </a>
          </div>
        </div>
      </div>
      <Train enabled={chooChoo} setEnabled={setChooChoo} />
    </>
  );
};

export default App;
